<template>
    <div id="editRolePage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editRole"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="siteusers_editRole"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                                                            <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" id="Mask" fill="#000000" fill-rule="nonzero" opacity="0.3" />
                                                            <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" id="Mask-Copy" fill="#000000" fill-rule="nonzero" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("siteusers_editRole") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="form-group validated">
                                                    <label for="editRoleForm_nameInput">{{ $t("siteusers_roleName") }} *</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                        </div>
                                                        <input v-model="name" @input="$v.name.$touch()" type="text" class="form-control" id="editRoleForm_nameInput" :placeholder="$t('siteusers_roleNameInputInfo')" @keyup="onChangeForm" :disabled="disableTreeCheckbox"/>
                                                    </div>
                                                    <div v-if="!$v.name.required" class="invalid-feedback">
                                                        {{ $t("error_fieldIsRequired") }}
                                                    </div>
                                                    <div v-else-if="!$v.name.minLen" class="invalid-feedback">
                                                        {{ $t("error_minLengthMsg", [$v.name.$params.minLen.min]) }}
                                                    </div>
                                                    <span class="form-text text-muted">{{ $t("siteusers_roleNameDetailInfo") }}</span>
                                                </div>

                                                <div class="form-group validated">
                                                    <label>{{ $t("siteusers_roleFeatures") }} *</label>

                                                    <app-jstree ref="featuresTree" v-model="features" :pDisableCheckbox="disableTreeCheckbox"></app-jstree>

                                                    <div v-if="!$v.features.required" class="invalid-feedback">
                                                        {{ $t("error_fieldIsRequired") }}
                                                    </div>
                                                    <span class="form-text text-muted">{{ $t("siteusers_roleFeaturesDetailInfo") }}</span>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button id="editRoleUpdateButton" @click="onUpdateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || !atLeastOneFeatureSelected || !isFormDataChanged">{{ $t("common_saveChanges") }}</button>
                                                    <button id="editRoleCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import i18n from "../../i18n";
import commonVueHelper from "../../helpers/commonVueHelper";
import Jstree from "./featurestree.vue";

const _roleFeatures = require("../../constants/roleFeatures");

export default {
    data() {
        return {
            name: "",
            features: [],
            atLeastOneFeatureSelected: false,
            siteId: this.$route.params.siteId,
            roleId: this.$route.params.roleId,
            isFormDataChanged: false,
            roleFeatureList: _roleFeatures.list,
            disableTreeCheckbox: false
        };
    },
    created: function() {
        console.log("Component(editRole)::created() - called");

        const payload = {
            siteId: this.siteId,
            roleId: this.roleId
        };
        this.getRoleById(payload);
    },
    mounted: function() {
        console.log("Component(editRole)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(editRole)::destroyed() - called");
        this.resetSiteRolesState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        name: {
            required,
            minLen: minLength(2)
        },
        features: {
            required
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        features: {
            handler: function(features) {
                var self = this;
                console.log("Component(editRole)::watch(features) - called with", features);

                self.atLeastOneFeatureSelected =
                    _.findIndex(features, function(feature) {
                        return feature.isEnabled === true;
                    }) >= 0;
                this.onChangeForm();
            },
            deep: true
        },

        currentRole: function(role) {
            console.log("Component(editRole)::watch(currentRole) - called with", role);
            if (role) {
                this.initRoleData();
                setTimeout(() => {
                    this.isFormDataChanged = false;
                }, 500);
            }
        },

        // Watch the changed of user language
        user: function(user) {
            console.log("Component(editRole)::watch(user) - called with ", user);
            if (user) {
                // To force language translation of updated features in component featurestree
                const currentFeatures = [...this.features];
                this.features = currentFeatures;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentRole", "user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getRoleById", "updateRole", "resetSiteRolesState"]),

        initRoleData() {
            if (this.currentRole) {
                this.features = this.reformat(this.currentRole.features);
                this.disableTreeCheckbox = this.currentRole.isDefault;
                if (this.currentRole.isDefault) {
                    this.name = i18n.t("ROLE_" + this.currentRole.name);
                } else {
                    this.name = this.currentRole.name;
                }
            }
        },

        reformat(editFeatures) {
            let editFeaturesByName = _.keyBy(editFeatures, "name");

            let featuresFormatted = [];

            _.each(this.roleFeatureList, feature => {
                let formattedFeature = {};
                formattedFeature.name = feature.name;
                formattedFeature.isEnabled = feature.isEnabled;

                let editFeature = editFeaturesByName[feature.name];
                if (editFeature) {
                    formattedFeature.isEnabled = editFeature.isEnabled;
                }

                if (feature.subFeatures) {
                    formattedFeature.subFeatures = [];

                    let editSubFeaturesByName = [];
                    if (editFeature) {
                        editSubFeaturesByName = _.keyBy(editFeature.subFeatures, "name");
                    }
                    if (feature.subFeatures) {
                        for (let subFeature of feature.subFeatures) {
                            let editSubFeature = editSubFeaturesByName[subFeature.name];

                            let formattedSubFeature = {};
                            if (subFeature.name !== "WALKWAY") {
                                formattedSubFeature.name = subFeature.name;
                                formattedSubFeature.isEnabled = subFeature.isEnabled;
                                if (editSubFeature) {
                                    formattedSubFeature.isEnabled = editSubFeature.isEnabled;
                                }
                                formattedFeature.subFeatures.push(formattedSubFeature);
                            }
                        }
                    }
                }

                featuresFormatted.push(formattedFeature);
            });
            return featuresFormatted;
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(editRole)::onCancelButton() - called");
            this.$router.push({ name: "roles", params: { fromAction: "cancelButton" } });
        },

        onUpdateButton() {
            console.log("Component(editRole)::onUpdateButton() - called");
            const data = {
                siteId: this.siteId,
                roleId: this.roleId,
                name: this.name,
                features: this.features
            };

            // Remove features/subFeatures with isEnabled to false
            data.features = _.reject(data.features, feature => {
                if (feature.isEnabled && feature.subFeatures) {
                    feature.subFeatures = _.reject(feature.subFeatures, subFeature => {
                        return subFeature.isEnabled === false;
                    });
                }
                return feature.isEnabled === false;
            });

            this.updateRole(data);
        },

        isEmptyForm() {
            // Return true if all control form are empty
            let isEmptyForm = true;
            if (this.name) {
                isEmptyForm = false;
            }
            return isEmptyForm;
        },

        onChangeForm() {
            if (this.currentRole && !this.currentRole.isDefault) {
                this.isFormDataChanged = true;
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "app-jstree": Jstree
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditRole", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
